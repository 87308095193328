<template>
  <layout :operation="'service-center'"></layout>
</template>

<script>
import Layout from "@/views/front/jsf35/operations";

export default {
  name: "ServiceCenter",
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped></style>
